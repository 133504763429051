<template>
  <shopping-cart></shopping-cart>
  <router-view></router-view>
</template>

<script>
import ShoppingCart from '@/components/ShoppingCart.vue'

export default {
  name: 'App',
  components: {
    ShoppingCart
  },
  computed: {
    currentVuePath() {
      return this.$route.path;
    },
  },
}
</script>