import { createRouter, createWebHistory } from 'vue-router'

// Import components for routing
import Home from '@/components/Home' // TODO: get rid of this since it was really just for testing

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/login', name: 'login', redirect: '/my-account' },
    { path: '/create-account', name: 'signup', component: () => import('@/components/Signup') },
    { path: '/my-account', name: 'account', component: () => import('@/components/Account') },
    { path: '/password/reset', name: 'passwordReset', component: () => import('@/components/PasswordReset') },
    { path: '/configure/:product', name: 'configure', component: () => import('@/components/Configurator') },
    { path: '/measure/:product', name: 'measure', component: () => import('@/components/Measurer') },
    { path: '/confirm/:product', name: 'confirm', component: () => import('@/components/Confirmer') },    
    { path: '/orders/quote', name: 'quote',  component: () => import('@/components/QuoteView') },
    { path: '/orders/details', name: 'order',  component: () => import('@/components/OrderView') },
  ],
});

export default router