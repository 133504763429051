// "That's my pot pie kitty!"

import { computed } from 'vue'
import { defineStore } from 'pinia'

export function useCartman() {
  const store = defineStore('cartman', {
    persist: false,
    state: () => {
        return {
            isCartShown: false,
            shouldReload: false, // whether or not the cart needs to reload items (after sending a quote to the cart, for instance)
        }
    },
  })();

  return {
    isCartShown: computed({
      get() { return store.isCartShown },
      set(val) { store.isCartShown = val }
    }),
    shouldReload: computed({
      get() { return store.shouldReload },
      set(val) { store.shouldReload = val }
    })
  }
}