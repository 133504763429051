<script setup>
  import { computed ,ref, onMounted, watch } from 'vue'
  import { Format } from '@/format';
  import { useRouter } from 'vue-router';
  import { debounce } from 'throttle-debounce';
  import { useBackend } from '@/composables/Backend';
  import { useCartman } from '@/composables/Cartman';
  import Image from '@/components/Image.vue';
  import Spinner from '@/components/Spinner.vue';
  import { MapFromCartItem } from '@/mappers/CartItem';

  const backend = useBackend();
  const router = useRouter();
  const cartman = useCartman();
  const error = ref('');
  const isWaitingOnBackend = ref(false);
  const hasParams = ref(false);
  // const itemType = ref('');

  // settings
  const btnCartClass = 'cart-button';
  const btnCartTextClass = 'link__text';
  const btnAccountID = 'accountName';
  const customer = ref(null);
  
  // properties
  const cartData = ref(false);
  const isLoading = ref(false);
  const cartError = ref('');
  const watches = []; // for tracking existing watches on cart items so they can be stopped before refreshing the data
  
  const itemIds = computed(() => {
    if (cartData.value && cartData.value.cart) {
      return cartData.value.cart.map((item) => {
        return item.itemId;
      });
    }

    return [];
  });

  const numItems = computed(() => {
    let num = 0;

    if (cartData.value && cartData.value.cart) {
      num = cartData.value.cart.reduce((sum, item) => {
        return sum + item.quantity;
      }, 0);
    }

    return num;
  });

  const pendingUpdates = ref([]);    
  const btnCartTextElements = [];
  const formattedCartTotal = computed(() => {
    let total = 0;

    if (cartData.value && cartData.value.subTotal) {
      total = cartData.value.subTotal;
    }
    const roundedTotal = Math.round(total);

    return Format.asCurrency(roundedTotal);    
  }); 
  
  // helper functions
  // function getItemTypeInfo(itemType) {
  //   const info = {
  //     productParam: '',
  //     placeholderImageName: '',
  //     transformData: null,
  //   };

  //   function getFabric(backendData) {
  //     if (backendData.fabric) {
  //       return backendData.fabric;
  //     }
  //     else {
  //       // backend currently doesn't always send a separate fabric SKU
  //       // so we'll have to parse the window SKU for the fabric sku
  //       // it seems to always be the first thing in the window SKU
  //       return backendData.sku.split('_')[0];
  //     }
  //   }

  //   switch(itemType) {        
  //     case 'cafeCurtains':
  //       info.productParam = 'cafe-curtains';
  //       info.placeholderImageName = 'cart-placeholder-cafecurtains.jpg';
  //       info.transformData = (backendData) => {
  //         return {
  //           type: backendData.type,
  //           windowName: backendData.label,
  //           itemId: backendData.itemId,
  //           selections: {
  //             package: '', // TODO: cart endpoint returns the label instead of the id
  //             fabric: getFabric(backendData),
  //             pleat: backendData.pleat,
  //             hardwareFinish: backendData.hardwareFinish,
  //             mountPosition: backendData.mountPosition,
  //             //rodSize: 0.375, // backend doesn't return it, but there is only one option
  //             panels: backendData.panels
  //           },
  //           rulers: {
  //           }
  //         }
  //       };
  //       break;
  //     case 'drapery':
  //       info.productParam = 'drapery';
  //       info.placeholderImageName = 'cart-placeholder-drapery.jpg';
  //       info.transformData = (backendData) => {
  //         return {
  //           type: backendData.type,
  //           windowName: backendData.label,
  //           itemId: backendData.itemId,
  //           selections: {
  //             package: '', // TODO: cart endpoint returns the label instead of the id
  //             fabric: getFabric(backendData),
  //             pleat: backendData.pleat,
  //             sheers: '', // HOW TO GET THIS?
  //             lining: backendData.lining,
  //             hardwareStyle: backendData.hardwareStyle,
  //             hardwareFinish: backendData.hardwareFinish,              
  //             //rodSize: 0.375, // HOW TO GET THIS?
  //             panels: backendData.panels
  //           },
  //           rulers: {
  //             windowWidthA: backendData.windowWidthA,
  //             windowWidthB: backendData.windowWidthB,
  //             windowWidthC: backendData.windowWidthC,
  //             stackLeft: backendData.stackLeft,
  //             stackRight: backendData.stackRight,
  //             windowHeightA: backendData.windowHeightA,
  //             windowHeightB: backendData.windowHeightB,
  //             windowHeightC: backendData.windowHeightC,
  //             mountingHeight: backendData.mountingHeight // TODO: looks like it may always return 0?
  //           }
  //         }
  //       };
  //       break;
  //     case 'roman-shades':
  //       info.productParam = 'roman-shades';
  //       info.placeholderImageName = 'cart-placeholder-romanshades.jpg';
  //       info.transformData = (backendData) => {
  //         return {
  //           type: backendData.type,
  //           windowName: backendData.label,
  //           itemId: backendData.itemId,
  //           selections: {
  //             fabric: getFabric(backendData),
  //             style: backendData.shade,
  //             lining: backendData.lining,
  //             mountPosition: backendData.mountPosition,
  //             customControl: backendData.customControl,
  //             shadeOperation: backendData.shadeOperation,
  //             cordPosition: backendData.cordPosition
  //           },
  //           rulers: {
  //             windowWidthA: backendData.windowWidthA,
  //             windowWidthB: backendData.windowWidthB,
  //             windowWidthC: backendData.windowWidthC,              
  //             windowHeightA: backendData.windowHeightA,
  //             windowHeightB: backendData.windowHeightB,
  //             windowHeightC: backendData.windowHeightC,
  //             mountingHeight: backendData.mountingHeight, // TODO: looks like it may always return 0?
  //             mountDepth: backendData.mountDepth // TODO: looks like it may always return 0?
  //           }
  //         }
  //       };
  //       break;
  //     case 'woven-woods':
  //       info.productParam = 'woven-woods';
  //       info.placeholderImageName = 'cart-placeholder-wovenwoods.jpg';
  //       info.transformData = (backendData) => {
  //         return {
  //           type: backendData.type,
  //           windowName: backendData.label,
  //           itemId: backendData.itemId,
  //           selections: {
  //             fabric: getFabric(backendData),
  //             lining: backendData.lining,
  //             mountPosition: backendData.mountPosition,
  //             customControl: backendData.customControl,
  //             shadeOperation: backendData.shadeOperation,
  //             controlPosition: backendData.cordPosition
  //           },
  //           rulers: {
  //             windowWidthA: backendData.windowWidthA,
  //             windowWidthB: backendData.windowWidthB,
  //             windowWidthC: backendData.windowWidthC,              
  //             windowHeightA: backendData.windowHeightA,
  //             windowHeightB: backendData.windowHeightB,
  //             windowHeightC: backendData.windowHeightC,
  //             mountingHeight: backendData.mountingHeight, // TODO: looks like it may always return 0?
  //             mountDepth: backendData.mountDepth
  //           }
  //         }
  //       };
  //       break;
  //   }

  //   return info;
  // }

  function loadData() {
    // clean up any existing watches on items so we don't create a memory leak
    watches.forEach(stop => {
      stop();
    });

    // get cart @todo
    cartError.value = '';
    isLoading.value = true;    
    backend.getCart()
      .then(result => {
        if (result == false || result.error) {
          var error = result.error;
          if (!error) {
            error = 'An error occurred communicating with the API.';
          }
          error = `Error getting shopping cart data: ${error}`;
          console.error(error);
          cartError.value = error;
        }
        else {
          // save result
          cartData.value = result;
      
          // add watch to each item so the backend can be updated when the quantity or label change
          if (cartData.value && cartData.value.cart) {
            cartData.value.cart.forEach(item => {
              watches.push(watch(item, (newItem) => {
                updateCartItem(item.itemId, item.label, newItem.quantity)
              }));
            });
          }          
        }      
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function updateCartText() {
    btnCartTextElements.forEach((element) => {
      if (numItems.value == 0) {
        element.innerText = "Cart";
      }
      else {
        element.innerText = `Cart (${numItems.value})`;
      }
    });
  }

  function subtractIfCan(item) {
    if (item.quantity > 1) {
      item.quantity--;
    }
  }

  function removeItem(item) {
    for (let i = 0; i < cartData.value.cart.length; i++) {
      if (cartData.value.cart[i].itemId == item.itemId) {
        cartData.value.cart.splice(i, 1);  
      }
    }
  }

  function editItem(cartItem) {
    // map data return from api to our data structure
    const data = MapFromCartItem.toProductData(cartItem);

    // build query
    const query = data.getQuery();

    // go to confirm screen    
    // without the timeout 'query' is undefined...not sure why...just javascript things? - DKW    )
    setTimeout(function() {
      cartman.isCartShown.value = false;
      router.push({ name: 'confirm', params: { product: data.product }, query: query });
    }, 100);
  }

  function checkout() {
    // Track the checkout initiation event
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'InitiateCheckout');
    }

    backend.getCheckoutUrl()
      .then(result => {
        location.href = result.checkoutUrl;
      });
  }

  // debouncing functions
  const updateCartItem = debounce(800, (itemId, itemLabel, itemQuantity) => {
    // add this update to the pending list (so a spinner can be shown)
    const updateId = `update@${Date.now()}`;
    pendingUpdates.value.push(updateId);

    // call the backend to update the label and quantity
    backend.updateCartItem(itemId, itemLabel, itemQuantity)
    .then(result => {
      if (result == false || result.error) {
        var error = result.error;
        if (!error) {
          error = 'An error occurred communicating with the API.';
        }
        error = `Error updating shopping cart item: ${error}`;        
        cartError.value = error;
      }
      else {
        cartData.value.subTotal = result.subTotal;    
        
        // remove this update from the pending list
        pendingUpdates.value.splice(pendingUpdates.value.indexOf(updateId), 1);
      }
    });  
  });

  // function buildParams() {
  //   const steps = data.steps;
  //   if (!steps || typeof steps !== 'object') {
  //     console.error('Steps data is not available or invalid');
  //     return '';
  //   }

  //   const params = Object.entries(steps)
  //     .filter(([key, step]) => key.startsWith('conf') || step.category === "configure" && step.selected && step.selected.value)
  //     .map(([key, step]) => `selections.${key}=${step.selected.value.id}`)
  //     .join('&');

  //   return params;
  // }

  // function buildNewProduct() {

  //   const params = buildParams();
  //   const navigateUrl = `/measure/${data.type}?${params}`;

  //   // Use Vue Router to navigate
  //   router.push(navigateUrl);
  //   cartman.isCartShown.value = false
  // }

  // onMounted hooks
  // Cart Button
  onMounted(() => {        
    // look for cart buttons on page (likely only one, but we'll be prepared to handle multiple just in case)
    const buttons = document.getElementsByClassName(btnCartClass);    

    // bind them to cartman.isCartShown
    for (let i = 0; i < buttons.length; i++) {
      buttons.item(i).addEventListener('click', function(){ cartman.isCartShown.value = !cartman.isCartShown.value; });
      
      // get the cart text elements that we'll need to update manually (to show item count)
      for (let x = 0; x < buttons.item(i).children.length; x++) {
        if (buttons.item(i).children.item(x).classList.contains(btnCartTextClass)) {
          btnCartTextElements.push(buttons.item(i).children.item(x));
        }        
      }
    }

    // add watch to num items computed property so we can update the cart text
    watch(numItems, () => { updateCartText(); });

    // get cart
    loadData();    

    // add watch to cart item id list so we can tell the backend an item was removed
    watch(itemIds, (newList, oldList) => {
      // if the cart is loading, then ignore list changes
      if (isLoading.value == false) {
        // add this update to the pending list (so a spinner can be shown)
        const updateId = `removal@${Date.now()}`;
        pendingUpdates.value.push(updateId);

        // find which items were removed
        const removed = oldList.filter((itemId) => {
          return newList.includes(itemId) == false;
        });

        // call the backend to remove the items
        removed.forEach((itemId) => {
          backend.removeCartItem(itemId)
          .then(result => {
            if (result == false || result.error) {
              var error = result.error;
              if (!error) {
                error = 'An error occurred communicating with the API.';
              }
              error = `Error removing shopping cart item: ${error}`;        
              cartError.value = error;
            }
            else {
              cartData.value.subTotal = result.subTotal;  

              // remove this update from the pending list
              pendingUpdates.value.splice(pendingUpdates.value.indexOf(updateId), 1);
            }
          });
        });
      }      
    });

    // add watch to cartman.shouldReload to listen for requests to reload the data    
    watch(cartman.shouldReload, (shouldReload) => {
      if (shouldReload) {
        loadData();
        cartman.shouldReload.value = false;
      }      
    });

  });

  // Customer Name
  onMounted(async () => {
    try {
        customer.value = await backend.getAccountInfo();
    } catch (error) {
        console.error('Failed to fetch customer data:', error);
        customer.value = null;
    }

    const accountButtonText = computed(() => {
      return customer.value ? customer.value.firstName || 'Sign In' : 'Sign In';
    });

    watch(accountButtonText, (newText) => {
      const button = document.getElementById(btnAccountID);
      if (button) {
        button.textContent = newText;  // Update the text content of the button
      }
    }, { immediate: true });
  });

  onMounted(() => {
    const swatchButtons = document.querySelectorAll('.swatch__button');
    swatchButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            e.stopPropagation();
            e.preventDefault();
            addSwatchToCart(button);
        });
    });

    const params = new URLSearchParams(window.location.search);
    hasParams.value = Array.from(params.keys()).length > 0;
  });

  function addSwatchToCart(button) {
    const sku = button.getAttribute('data-sku');
    const data = {
        type: 'swatch',
        swatch: sku,
    };
    isWaitingOnBackend.value = true;

    backend.addCartSwatchItem(data)
        .then(result => {
            if (result === false || result.error) {
                error.value = result.error ? result.error : 'Sorry, an error occurred attempting to update your shopping cart. Please try again later or contact support.';
                console.error(error.value);
            } else {
                cartman.shouldReload.value = true;
                cartman.isCartShown.value = true;
            }
        })
        .catch(err => {
            error.value = 'An unexpected error occurred.';
            console.error(error.value, err);
        })
        .finally(() => {
            isWaitingOnBackend.value = false;
        });
  }
</script>

<template>
  <div class="shopping-cart" v-show="cartman.isCartShown.value">
    <div class="container-fluid">
      <div class="row">
        <div class="side-panel modal--overlay-panel col-lg-6">
          <a class="modal--close" href="#" @click="cartman.isCartShown.value = false"></a>
          <div class="cart__button-header">
            <h3>Beautiful choice!</h3>
          </div>
          <div class="cart_button-wrapper">
            <a class="cart_button-view" href="/configure/drapery">Build Another Drapery Item</a>
            <a class="cart_button-view" href="/configure/cafe-curtains">Build Another Café Curtain</a>
            <a class="cart_button-view" href="/configure/roman-shades">Build Another Roman Shade</a>
            <a class="cart_button-view" href="/configure/woven-woods">Build Another Woven Woods Shade</a>
          </div>
        </div>
        <div class="cart-panel cart col-lg-6">
          <div class="cart-header cart__header header">
            <div class="cart-row">
              <div class="description">Your Cart</div>
              <div class="quantity">Quantity</div>
              <div class="unit-price">Unit Price</div>
            </div>
          </div>
          <div class="cart-body cart__body">
            <div v-if="cartError">{{ cartError }}</div>
            <p v-if="isLoading">Loading...</p>
            <div class="cart-row" v-for="item in cartData.cart" :key="item.itemId">
              <div class="description">
                <div>
                  <img v-if="item.imageUrl" :src="item.imageUrl" />
                  <!-- <Image v-else :src="getItemTypeInfo(item.type).placeholderImageName"></Image> -->
                  <Image v-else src="cart-placeholder-drapery.jpg" class="temp--img"></Image>
                </div>
                <div>
                  <input v-model="item.label" placeholder="e.g. Primary Bedroom West" />
                  <p>{{ item.description }}</p>
                  <a v-if="item.isEditable == true" @click="editItem(item)" class="link pretext cart__item-edit-btn">Edit</a>
                  <!-- {{ item }} -->
                </div>
              </div>
              <div class="quantity">
                <div>
                  <div class="subtract" @click="subtractIfCan(item)">-</div>
                  <input class="form-control" v-model="item.quantity" />
                  <div class="add" @click="item.quantity++">+</div>
                </div>
                <div>
                  <a @click="removeItem(item)">Remove</a>
                </div>
              </div>
              <div class="unit-price">
                <span v-if="item.isOnSale === true" class="pricing--strikeout">{{ item.origCostText }}</span>            
                {{ item.costText }}
              </div>
            </div>            
          </div>
          <div class="cart-footer cart__footer">
            <div class="cart__total total">
              <label class="total__label pretext">Subtotal</label>              
              <span class="total__amount">
                <Spinner v-if="pendingUpdates.length > 0"></Spinner>
                <template v-else>{{ formattedCartTotal }}</template>
              </span>
            </div>
            <div>
              <a class="cart__checkout-btn w-button" href="#" @click="checkout">Check Out →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>