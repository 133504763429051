// maps the data from the cart endpoint to our product data set

import { useData } from "@/composables/Data";

export class MapFromCartItem {
  static toProductData(apiCartItem) {
    // determine product
    let product = apiCartItem.type;
    if (product == 'cafeCurtains') {
      product = 'cafe-curtains';
    }

    // init product data
    const data = useData(product, true);

    // set non-step specific values
    data.product = product;
    data.itemId.value = apiCartItem.itemId;
    data.windowName.value = apiCartItem.label;

    // transform api data value into matching values for STEPS in product data
    const steps = [];
    
    this.copy(apiCartItem, steps, 'cordPosition');
    this.copy(apiCartItem, steps, 'customControl');
    steps['fabric'] = this.getFabric(apiCartItem);
    this.copy(apiCartItem, steps, 'hardwareFinish');
    this.copy(apiCartItem, steps, 'lining');
    this.copy(apiCartItem, steps, 'mountPosition');

    // try to get the package
    if (apiCartItem.name) {
      if (product == 'cafe-curtains') {
        // the api sends back the name of the package, but for cafe curtains, it comes back as 'Drapery & Hardware'
      }
    }

    // get panels, but will need to transform the data if there are 4 panels
    if (apiCartItem.panels) {
      let panels = apiCartItem.panels;
      if (panels == 'quad') {
        panels = 'four';
      }

      steps['panels'] = panels;
    }

    this.copy(apiCartItem, steps, 'pleat');
    this.copy(apiCartItem, steps, 'shadeOperation');

    // get the sheers SKU; have to parse the overall item sku for it, though
    if (product == 'drapery') {
      // parse SKU for sheers fabric sku
      let sheers = apiCartItem.sku.split('_')[11];
      if (sheers == '00000') {
        sheers = 'none';
      }

      steps['sheers'] = sheers;
    }    

    if (apiCartItem.shade) {
      steps['style'] = apiCartItem.shade;
    }    

    // handle ruler data
    const rulers = [];

    this.copy(apiCartItem, rulers, 'windowWidthA');
    this.copy(apiCartItem, rulers, 'windowWidthB');
    this.copy(apiCartItem, rulers, 'windowWidthC');

    this.copy(apiCartItem, rulers, 'windowHeightA');
    this.copy(apiCartItem, rulers, 'windowHeightB');
    this.copy(apiCartItem, rulers, 'windowHeightC');

    this.copy(apiCartItem, rulers, 'stackLeft');
    this.copy(apiCartItem, rulers, 'stackRight');

    this.copy(apiCartItem, rulers, 'mountingHeight');

    // select the options
    Object.entries(data.steps).forEach((kv) => {
      const step = kv[1];
      
      // if there is data for the current step, select the option
      if (steps[step.id]) {        
        const newSelectedOption = step.options.find((option) => option.id == steps[step.id]);
        if (newSelectedOption) {
          kv[1].selected.value = newSelectedOption;
        }
      }

      // if the current step has rulers, set the current values
      if (step.rulers) {
        step.rulers.forEach((ruler) => {
          if (rulers[ruler.id]) {
            ruler.current.value = rulers[ruler.id];
          }
        });
      }
    });

    // return data now that all selections have been made
    return data;
  }

  static getFabric(backendData) {
    if (backendData.fabric) {
      return backendData.fabric;
    }
    else {
      // backend currently doesn't always send a separate fabric SKU
      // so we'll have to parse the product SKU for the fabric sku
      // it seems to always be the first thing in the product SKU
      return backendData.sku.split('_')[0];
    }
  }

  // if the prop exists in the source then it will copy it to the target
  // otherwise, it just ignores it
  static copy(source, target, prop) {
    if (source[prop]) {
      target[prop] = source[prop];
    }
  }
}