import { computed } from 'vue';
import { setupProductData } from '../../product-data-setup';

export function useDrapery(doNotPersistState) {
    const data = setupProductData(doNotPersistState, {
        heading: 'Drapery & Hardware',
        subheading: 'Refined & Timeless',
        preMeasureTitle: 'Where will you be installing this drapery?',
        type: 'drapery',
        defaultCartImage: 'cart-placeholder-drapery.jpg',
        packageStepId: 'package',        
        stepsPerGroup: 5,
        steps: {
            package: {
                id: 'package',
                category: 'configure',
                name: 'Configuration',
                layout: 'button-group',
                showOnConfirmation: false,
                options: [
                    { label: 'Drapery & Hardware', id: 'drapery-hardware', image: 'icon-drapery-hardware.png' },
                    { label: 'Drapery Only', id: 'drapery-only', image: 'icon-drapery-only.png' },
                    { label: 'Hardware Only', id: 'hardware-only', image: 'icon-hardware-only.png', skipMeasurer: true }
                ]
            },
            fabric: {
                id: 'fabric',
                category: 'configure',
                name: 'Fabric & Color',
                layout: 'swatches',
                packages: ['drapery-hardware', 'drapery-only'],
                checkStock: true,
                showOnConfirmation: true,
                options: [
                    { category: 'Essentials', label: 'Essentials • Moon', id: 'MOESS', image: 'swatch_moon.jpg' },
                    { category: 'Essentials', label: 'Essentials • Bone', id: 'BOESS', image: 'swatch_bone.jpg' },
                    { category: 'Essentials', label: 'Essentials • Sand', id: 'SDESS', image: 'swatch_sand.jpg' },
                    { category: 'Essentials', label: 'Essentials • Birch', id: 'BCESS', image: 'swatch_birch.jpg' },
                    { category: 'Essentials', label: 'Essentials • Ash', id: 'ASESS', image: 'swatch_ash.jpg' },
                    { category: 'Essentials', label: 'Essentials • Sage', id: 'SGESS', image: 'swatch_sage.jpg' },
                    { category: 'Essentials', label: 'Essentials • Rose', id: 'RSESS', image: 'swatch_rose.jpg' },
                    { category: 'Stripes', label: 'Stripes • Ivory Stripe', id: 'IVSTR', image: 'swatch_ivory-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Oyster Stripe', id: 'OYSTR', image: 'swatch_oyster-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Denim Stripe', id: 'DESTR', image: 'swatch_denim-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Petal Stripe', id: 'PESTR', image: 'swatch_petal-stripe.jpg' },
                    { category: 'Sheers', label: 'Sheers • Moon', id: 'MOSHR', image: 'swatch_moonsheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Bone', id: 'BOSHR', image: 'swatch_bonesheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Pebble', id: 'PBSHR', image: 'swatch_pebblesheer.jpg' },
                    { category: 'Hues', label: 'Hues • Quartz', id: 'QZHUE', image: 'swatch_quartz.jpg' },
                    { category: 'Hues', label: 'Hues • Driftwood', id: 'DRHUE', image: 'swatch_driftwood.jpg' },
                    { category: 'Hues', label: 'Hues • Flax', id: 'FLHUE', image: 'swatch_flax.jpg' },
                    { category: 'Hues', label: 'Hues • Granite', id: 'GRHUE', image: 'swatch_granite.jpg' },
                    { category: 'Hues', label: 'Hues • Umber', id: 'UMHUE', image: 'swatch_umber.jpg' },
                    { category: 'Hues', label: 'Hues • Cognac', id: 'CGHUE', image: 'swatch_cognac.jpg' },
                    { category: 'Hues', label: 'Hues • Juniper', id: 'JUHUE', image: 'swatch_juniper.jpg' },
                    // { category: 'Sale', label: 'Essentials • Pebble', id: 'PBESS', image: 'swatch_pebble.jpg' },
                    { category: 'Sale', label: 'Essentials • Fawn', id: 'FWESS', image: 'swatch_fawn.jpg' },
                    { category: 'Sale', label: 'Essentials • Storm', id: 'SMESS', image: 'swatch_storm.jpg' },
                ]
            },
            pleat: {
                id: 'pleat',
                category: 'configure',
                name: 'Pleat',
                layout: 'button-group',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: true,
                options: [
                    { label: 'Box', id: 'box', image: 'pleat-box-reverse.svg' },
                    { label: 'Tailored', id: 'tailored', image: 'pleat-euro-tailored.svg' },
                    { label: 'Pinch', id: 'pinch', image: 'pleat-french-pinch.svg' }
                ]
            },
            sheers: {
                id: 'sheers',
                category: 'configure',
                name: 'Sheers',
                layout: 'big-circles',
                packages: ['drapery-hardware'],                
                checkStock: true,
                description: 'Sheers are ideal for rooms where you want to keep your drapes open to let the light in but still want some privacy.',
                showOnConfirmation: true,
                conflicts: {
                    stepId: 'fabric', // id of the step that has options that conflict with the options in THIS step
                    optionProperty: 'category'
                },
                options: [
                    { label: 'None', id: 'none', image: 'diagram-sheers-none.jpg', default: true },
                    { label: 'Moon', id: 'MOSHR', image: 'swatch_moonsheer.jpg', incompatibleOptions: ['Sheers'] },
                    { label: 'Bone', id: 'BOSHR', image: 'swatch_bonesheer.jpg', incompatibleOptions: ['Sheers'] },
                    { label: 'Pebble', id: 'PBSHR', image: 'swatch_pebblesheer.jpg', incompatibleOptions: ['Sheers'] }
                ]
            },
            lining: {
                id: 'lining',
                category: 'configure',
                name: 'Lining',
                layout: 'button-group',
                packages: ['drapery-hardware', 'drapery-only'],
                description: 'Choose how much light your drapery will allow to pass through.',
                showOnConfirmation: true,
                conflicts: {
                    stepId: 'fabric', // id of the step that has options that conflict with the options in THIS step
                    optionProperty: 'category'
                },
                options: [
                    { label: 'Unlined', id: 'unlined', image: 'icon-lining-unlined.svg' },
                    { label: 'Privacy', id: 'privacy', image: 'icon-lining-privacy.svg', default: true, incompatibleOptions: ['Sheers'] },
                    { label: 'Blackout', id: 'blackout', image: 'icon-lining-blackout.svg', incompatibleOptions: ['Sheers'] },
                ]
            },
            hardwareStyle: {
                id: 'hardwareStyle',
                category: 'configure',
                name: 'Hardware Style',
                layout: 'button-group',
                packages: ['drapery-hardware', 'hardware-only'],
                description: 'Our drapery is designed for Rod & Ring style hardware.',
                // instructions: { title: 'How to Choose Hardware', yt_id: 'rp1WSM_pRow' },
                showOnConfirmation: true,
                options: [
                    { label: 'Right Angle', id: 'right-angle-return', image: 'icon-hw-right-angles.svg' },
                    { label: 'Curved', id: 'curved-return', image: 'icon-hw-curved.svg' },
                    { label: 'Finial', id: 'finial', image: 'icon-hw-finial.svg' },
                ]
            },
            hardwareFinish: {
                id: 'hardwareFinish',
                category: 'configure',
                name: 'Finish',
                layout: 'big-circles',
                packages: ['drapery-hardware', 'hardware-only'],
                showOnConfirmation: true,
                options: [
                    { label: 'Brass', id: 'satin-brass', image: 'finish-aged-brass.jpg' },
                    { label: 'Bronze', id: 'oil-rubbed-bronze', image: 'finish-oil-rubbed-bronze.jpg' },
                    { label: 'Black', id: 'matte-black', image: 'finish-matte-black.jpg', default: true },    
                ]
            },            
            rodSize: {
                id: 'rodSize',
                category: 'configure',
                name: 'Size',
                layout: 'length',
                packages: ['drapery-hardware', 'hardware-only'],
                showOnConfirmation: true,
                options: [
                    { label: '1 " Rod', id: '1', diameter: 1, image: 'icon-1-inch.png' },
                    { label: '3/4 " Rod', id: '3/4', diameter: 0.75, image: 'icon-3-4-inch.png' },
                ],
                rulers: [
                    { id: 'windowWidthA', min: computed(() => 12), max: computed(() => 288), precision: 1, starting: 72.0 }
                ]
            },
            // measure the window
            windowWidth: {
                id: 'windowWidth',
                category: 'measure',
                name: 'Window Width',
                title: 'Measure Window Width',
                secondaryTitle: 'Window Width',                
                layout: 'rulers',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'width',
                        html: '<p>Measure your window\'s horizontal width from outside edge to outside edge in 3 places: top, middle and bottom.</p>',
                        image: 'diagram-measure-outside-width.svg',
                        // instructions: { title: 'How to Measure', yt_id: 'fGHoIYmuGMQ' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                ],
                rulers: [
                    { id: 'windowWidthA', label: 'A', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 72.0 },
                    { id: 'windowWidthB', label: 'B', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 72.0 },
                    { id: 'windowWidthC', label: 'C', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 72.0 },
                ],
            },
            stackWidth: {
                id: 'stackWidth',
                category: 'measure',
                name: 'Stack Width',
                title: 'Measure Stack Width',
                secondaryTitle: 'Total Drapery Width',
                layout: 'rulers',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                // @todo certain options need to be calculated - this was my approach and may need adjustment
                calculateMeasurement: true,
                options: [
                    { 
                        id: 'stackWidth',
                        html: '<p>Stack is the additional width added to your drapery & hardware beyond your window width, keeping your drapery panels from covering your window as well as making your window appear larger.</p><p>We recommend selecting 10" stack width for each side if you have the space to do so.</p>',
                        image: 'diagram-measure-stack.svg',
                        // instructions: { title: 'Learn About Stack', yt_id: 'H2gDq-K2gH4' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                ],
                rulers: [
                    { id: 'stackLeft', label: 'L', min: computed(() => 0), max: computed(() => 24), precision: 1, starting: 8.0 },
                    { id: 'stackRight', label: 'R', min: computed(() => 0), max: computed(() => 24), precision: 1, starting: 8.0 },
                ],
            },
            windowHeight: {
                id: 'windowHeight',
                category: 'measure',
                name: 'Measure Height',
                title: 'Measure Window Height',
                secondaryTitle: 'Window Height',
                layout: 'rulers',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'height',
                        html: '<p>Measure your window\'s vertical height from outside top edge to the floor in 3 places: left, center & right.</p>',
                        image: 'diagram-measure-height-to-floor.svg',
                        // instructions: { title: 'How to Measure', yt_id: 'fGHoIYmuGMQ' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                ],
                rulers: [
                    { id: 'windowHeightA', label: 'A', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 84.0 },
                    { id: 'windowHeightB', label: 'B', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 84.0 },
                    { id: 'windowHeightC', label: 'C', min: computed(() => 12), max: computed(() => 240), precision: 1, starting: 84.0 },
                ],
            },
            mountingHeight: {
                id: 'mountingHeight',
                category: 'measure',
                name: 'Mount Height',
                title: 'Total Mounting Height',
                secondaryTitle: 'Total Mounting Height',                
                layout: 'rulers',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                calculateMeasurement: true,
                options: [
                    { 
                        id: 'mountHeight',
                        html: '<p>Measure your additional Mounting Height above the window.</p><p>Everhem Hardware requires a minimum height of 3 ½" above your window trim to be properly mounted; however, we recommend selecting 10" above the top of the window frame or moulding if you have the room to do so.</p>',
                        image: 'diagram-measure-mounting.svg',
                        // instructions: { title: 'How to Measure "Mounting Height"', yt_id: 'caEvVBqlEaw' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.',
                        rulerAdjustment : { 
                            label: 'Dropdown',
                            tip: {
                                title: 'Designer Dropdown Recommendation',
                                html: 'After you\'ve determined your mounting height, deduct the outer diameter of the rings you will use to hang your drapery. This will ensure your drapery panel length will just kiss the floor.',
                            },
                            range: {
                                enabled: computed(() => {
                                    return data.currentPackage.value.id == 'drapery-only';
                                }),
                                min: 0,
                                max: 7,
                                precision: 0.5,
                            },
                            starting: (data) => {
                                if (data.currentPackage.value.id == 'drapery-only') {
                                    return 2;
                                }
                                else {
                                    return 1 + data.steps.rodSize.selected.value.diameter;
                                }
                            }
                        },                        
                    },
                ],
                rulers: [
                    { id: 'mountingHeight', label: 'A', min: computed(() => 3), max: computed(() => 24), precision: 1, starting: 7.0 },
                ],
            },
            panels: {
                id: 'panels',
                category: 'measure',
                name: 'Panels',
                title: 'Choose Panel Configuration',
                secondaryTitle: ' ',
                layout: 'big-circles',
                packages: ['drapery-hardware', 'drapery-only'],
                description: '<p>We recommend single panel for narrow windows or for windows that flank a large piece such as a bed or sofa; choose double panel for wider and for single windows.</p>',
                showOnConfirmation: true,
                recommendedOption: computed(() => {
                    const width = data.steps.windowWidth.rulersMax.value;
                    const sorted = data.steps.panels.options.slice().sort((a, b) => b.recommendAtWidth - a.recommendAtWidth);
                    return sorted.find(option => option.recommendAtWidth <= width) || data.steps.panels.options[0];
                }),
                options: [
                    { label: 'Single Panel', id: 'single', image: 'drapery-single-panel.jpg', numPanels: 1, minWidth: 0, maxWidth: 145, recommendAtWidth: 0 },
                    { label: 'Double Panel', id: 'double', image: 'drapery-double-panel.jpg', numPanels: 2, minWidth: 0, maxWidth: 288, recommendAtWidth: 36 },
                    { label: 'Four Panel', id: 'four', image: 'drapery-four-panel.jpg', numPanels: 4, minWidth: 145, maxWidth: 288, recommendAtWidth: 145 },
                ],
            }
        }
    })

    // add measurement total computations
    data.getTotals = function() {
        let totals = [];

        if (data.currentPackage.value.skipMeasurer) {
            totals = [
                {
                    layout: 'individual',
                    values: [
                        { label: 'Rod Length', id: 'rod-length', getValue: () => {
                            return Number(data.steps.rodSize.rulersMax.value);
                        }},
                    ]
                }
            ]
        }
        else {
            totals = [
                {
                    layout: 'formula',
                    values: [
                        { label: 'Width', id: 'width', getValue: () => {
                            return Number(data.steps.windowWidth.rulersMax.value);
                        }},
                        { label: 'Stack (L)', id: 'stack-left', getValue: () => {                
                            return Number(data.steps.stackWidth.rulers.filter((ruler) => {
                                return ruler.id == 'stackLeft';
                            })[0].current.value);
                        }},
                        { label: 'Stack (R)', id: 'stack-right', getValue: () => {
                            return Number(data.steps.stackWidth.rulers.filter((ruler) => {
                                return ruler.id == 'stackRight';
                            })[0].current.value);
                        }},
                        { label: 'Total Width', id: 'total', getValue: () => {
                            return  totals[0].values[0].getValue() + 
                                    totals[0].values[1].getValue() +
                                    totals[0].values[2].getValue();
                        }}
                    ]
                },
                {
                    layout: 'formula',
                    values: [
                        { label: 'Height', id: 'height', getValue: () => {
                            return Number(data.steps.windowHeight.rulersMax.value);
                        }},
                        { label: 'Mount', id: 'mount', getValue: () => {
                            return Number(data.steps.mountingHeight.rulersMax.value);
                        }},
                        { label: 'Total Mounting Height', id: 'total', getValue: () => {
                            return totals[1].values[0].getValue() +
                                   totals[1].values[1].getValue();
                        }}
                    ]
                },      
                {
                    layout: 'text',
                    values: [
                        { label: 'Panel Dimensions', id: 'total', getValue: () => {
                            const totalWidth = Number(totals[0].values[3].getValue());
                            const totalMountingHeight = Number(totals[1].values[2].getValue());

                            let totalHeight;
                            const isDraperyOnly = data.steps.package.selected.value.id === 'drapery-only';

                            if (isDraperyOnly) {
                                const draperyOnlyDeduction = Number(data.steps.mountingHeight.selected.value.rulerAdjustment.current.value);
                                totalHeight = totalMountingHeight - draperyOnlyDeduction;
                            } else {
                                // Standard deduction based on rod size
                                const rodDeduction = data.steps.rodSize.selected.value.diameter + 1;
                                totalHeight = totalMountingHeight - rodDeduction;
                            }
        
                            const numPanels = Number(data.steps.panels.selected.value.numPanels);                            
        
                            return `${totalWidth/numPanels}"W x ${totalHeight}"L (X${numPanels})`;
                        }},
                    ]
                }      
            ];
        }

        return totals;
    }    

    // add rendering layers
    data.rendering = {
        fabric: {
            id: 'fabric',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const hardwareStyleSelected = data.steps.hardwareStyle.selected.value.id;
                const fabricSelected = data.steps.fabric.selected.value.id;
                const pleatSelected = data.steps.pleat.selected.value.id;
                const liningSelected = data.steps.lining.selected.value.id;

                console.log(`${hardwareStyleSelected}_${fabricSelected}_${pleatSelected}_${liningSelected}.png`);
                return `/drapery/fabric/${hardwareStyleSelected}_${fabricSelected}_${pleatSelected}_${liningSelected}.png`;
            }
        },
        sheers: {
            id: 'sheers',
            shouldShow: () => { 
                return data.steps.sheers.selected.value.id != 'none';
            },
            getImageUrl: () => {
                const sheersSelected = data.steps.sheers.selected.value.id;
                const pleatSelected = data.steps.pleat.selected.value.id;
                const hardwareReturnSelected = data.steps.hardwareStyle.selected.value.id;
                
                return `/drapery/sheers/${sheersSelected}_${pleatSelected}_${hardwareReturnSelected}.png`;
            }
        },
        hardware: {
            id: 'hardware',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const hardwareReturnSelected = data.steps.hardwareStyle.selected.value.id;
                const hardwareFinishSelected = data.steps.hardwareFinish.selected.value.id;
                const rodSizeSelected = data.steps.rodSize.selected.value.diameter;
                const sheersSelected = data.steps.sheers.selected.value.id;

                let sheersDoubleRod = ''
                if (sheersSelected != 'none') {
                    sheersDoubleRod = '_doubleRod'
                } else {
                    sheersDoubleRod = ''
                }

                return `/drapery/hardware/${hardwareReturnSelected}_${hardwareFinishSelected}_${rodSizeSelected}${sheersDoubleRod}.png`;
            }
        }
    }    

    return data;
}