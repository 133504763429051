import { defineStore } from 'pinia'
import { Helpers } from '@/helpers';

export function useStateStore(getStepById, storage) {  
  // define a store to maintain state
  const store = defineStore('product', {
    persist: {
      storage: storage
    },
    state: () => {
        return {
            itemId: '',
            windowName: '',
            selections: {},
            rulers: {},
            rulerLocks: {},
            measureStepNum: 0,
            rulerAdjustments: {},
        }
    },
  })();
  
  // run storage config
  storage.config(store.$hydrate)

  // getter/setter for step selections
  function getSelected(stepId) {
    let selected = null;

    // get default if no selection in the store
    if (store.selections[stepId] == null) {
        selected = Helpers.getDefaultOption(getStepById(stepId));
        store.selections[stepId] = selected.id;
    }
    else {
        const matches = getStepById(stepId).options.filter((option) => {
            return option.id == store.selections[stepId];
        });

        if (matches != null || matches.length > 0) {                
            selected = matches[0];
        }
    }

    return selected;
  }

  function setSelected(stepId, option) {
    store.selections[stepId] = option.id;
  }

  // getter/setter for ruler measurements
  function getRulerValue(rulerId, defaultVal) {
    let val = defaultVal;

    if (store.rulers[rulerId] != null) {
      val = store.rulers[rulerId];
    }

    return val;
  }

  function setRulerValue(rulerId, val) {
    store.rulers[rulerId] = val;
  }

  // getter/setter for ruler locks
  function getRulersLocked(stepId) {
    let locked = true;

    if (store.rulerLocks[stepId] != null) {
      locked = store.rulerLocks[stepId];
    }

    return locked;
  }

  function setRulersLocked(stepId, isLocked) {
    store.rulerLocks[stepId] = isLocked;
  }

  // getter/setter for window name
  function getWindowName() {
    return store.windowName;
  }

  function setWindowName(val) {
    store.windowName = val;
  }

  // getter/setter for item id
  function getItemId() {
    return store.itemId;
  }

  function setItemId(val) {
    store.itemId = val;
  }

  // getter/setter for current measure step
  function getCurrentMeasureStep() {
    return store.measureStepNum;
  }

  function setCurrentMeasureStep(val) {
    store.measureStepNum = val;
  }

  // getter/setter for ruler adjustments
  function getRulerAdjustment(stepId, optionId, defaultVal) {
    let val = defaultVal;

    if (store.rulerAdjustments[`${stepId}-${optionId}`] != null) {
      val = store.rulerAdjustments[`${stepId}-${optionId}`];
    }

    return val;
  }

  function setRulerAdjustment(stepId, optionId, val) {
    store.rulerAdjustments[`${stepId}-${optionId}`] = val;
  }

  return {
    getSelected,
    setSelected,
    getRulerValue,
    setRulerValue,
    getRulersLocked,
    setRulersLocked,
    getWindowName,
    setWindowName,
    getItemId,
    setItemId,
    getCurrentMeasureStep,
    setCurrentMeasureStep,
    getRulerAdjustment,
    setRulerAdjustment
  }
}