export class Format {
  static asCurrency(value) {
    return `$${Intl.NumberFormat().format(value)}`;
  }

  static asDate(date) {
    const dateObj = new Date(date);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return dateObj.toLocaleDateString(undefined, options);
  }
}