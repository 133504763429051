import { computed, watch, onBeforeMount } from 'vue';
import { useStateStore } from './composables/state/StateStore';
import { useUrlStorage } from './composables/state/UrlStorage';
import { useMemoryStorage } from './composables/state/MemoryStorage';
import { Helpers } from './helpers';

// adds common things to the product data object
// gets called by each class in product-data
export function setupProductData(doNotPersistState, data) {
    // add current package stuff, if applicable
    if (data.packageStepId) {
        // add currentPackage property to root
        data.currentPackage = computed(() => {
            const selectedPackage = Object.entries(data.steps).map((kv) => kv[1]).filter((step) => {
                return step.id == data.packageStepId;
            })[0].selected.value;

            return selectedPackage;
        });

        // add a computed property for disabled to each step
        Object.entries(data.steps).forEach((kv) => {
            const step = kv[1];
            step.disabled = computed(() => {
                return step.packages && !step.packages.includes(data.steps[data.packageStepId].selected.value.id);
            });
        });
    }
    
    // setup option incompatibility
    Object.entries(data.steps).forEach((kv) => {            
        const step = kv[1];
        if (step.conflicts) {
            step.options.forEach((option) => {
                if (option.incompatibleOptions) {
                    option.disabled = computed(() => {                        
                        return option.incompatibleOptions.includes(data.steps[step.conflicts.stepId].selected.value[step.conflicts.optionProperty]);
                    });                    
                }
            });
        }
    });
    
    // setup watchers
    onBeforeMount(() => {        
        steps.forEach((step) => {
            step.options.forEach((option) => {
                // watcher to automatically change the selected option if the current one becomes disabled
                if (option.disabled) {
                    watch(option.disabled, () => {
                        if (option.disabled.value && step.selected.value.id == option.id) {
                            step.selected.value = Helpers.getDefaultOption(step);
                        }
                    });
                }
            })
        })
    })

    // define a store to maintain state
    let storage = null;
    const query = {}; // only set when doNotPersistState is true
    if (doNotPersistState) {
        // store state in memory only
        storage = useMemoryStorage(query);

        data.getQuery = function() { return query; };
    }
    else {
        // store state in the url
        storage = useUrlStorage();
    }

    const store = useStateStore((stepId) => {
        return data.steps[stepId];
    }, storage);

    // add common properties to root
    data.windowName = computed({
        get() { return store.getWindowName() },
        set(val) { store.setWindowName(val) }
    });

    data.itemId = computed({
        get() { return store.getItemId() },
        set(val) { store.setItemId(val) }
    })

    data.currentMeasureStep = computed({
        get() { return store.getCurrentMeasureStep() },
        set(val) { store.setCurrentMeasureStep(val) }
    });

    // add properties to steps and rulers, if applicable
    const steps = Object.entries(data.steps).map((kv) => kv[1]);
    steps.forEach(step => {
        // add selected property to each step
        step.selected = computed({
            get() { return store.getSelected(step.id) },
            set(option) { store.setSelected(step.id, option); }
        });

        // handle when this step should cascade its selection to other steps
        // ie, mountPosition to windowHeight and windowWidth
        if (step.cascadeSelectionToStepIds) {
            watch(step.selected, (selected) => {
                step.cascadeSelectionToStepIds.forEach((receivingStepId) => {                    
                    store.setSelected(receivingStepId, selected);
                });
            })
        }

        // handle if a step option has a ruler adjustment
        step.options.forEach((option) => {
            if (option.rulerAdjustment != null) {
                // add current property
                option.rulerAdjustment.current = computed({
                    get() { return store.getRulerAdjustment(step.id, option.id, option.rulerAdjustment.starting(data)) },                    
                    set(val) { store.setRulerAdjustment(step.id, option.id, val) }
                });

                // Initialize current value if not yet set (only if needed)
                if (option.rulerAdjustment.current.value === undefined) {
                    option.rulerAdjustment.current.value = option.rulerAdjustment.starting(data);
                }

                // build range options if applicable
                if (option.rulerAdjustment.range) {
                    const options = [];
                    for (let i = option.rulerAdjustment.range.min; i <= option.rulerAdjustment.range.max; ) {
                        options.push({
                            value: i,
                            text: `${i}"`
                        });
                        i = i + option.rulerAdjustment.range.precision;
                    }
                    option.rulerAdjustment.range.options = options;
                }
            }
        });

        // add ruler related properties
        if (step.rulers != null) {
            // add property for ruler locks
            step.rulersLocked = computed({
                get() { return store.getRulersLocked(step.id) },
                set(val) { store.setRulersLocked(step.id, val) }
            });

            // add current property for each ruler
            step.rulers.forEach(ruler => {
                ruler.current = computed({
                    get() { return store.getRulerValue(ruler.id, ruler.starting); },
                    set(val) { store.setRulerValue(ruler.id, val); }
                });
            });

            // add computed property for max measurement to any step that has rulers
            step.rulersMax = computed(() => {
                const max = Math.max(...step.rulers.map((ruler) => {
                    return ruler.current.value;
                }));

                // Only apply adjustment if the step is not 'mountingHeight'
                if (step.id !== 'mountingHeight') {
                    if (step.selected.value.rulerAdjustment) {
                        const adjustment = step.selected.value.rulerAdjustment.current.value;
                        return max + adjustment;
                    }
                }
                
                // For 'mountingHeight' or if no adjustment is present, just return max
                return max;
            });

            // add computed property for whether a warning should be shown to user for measurements that are very different
            step.hasMeasureDiscrepency = computed(() => {
                const deviation = Helpers.getStandardDeviation(step.rulers.map((ruler) => { return ruler.current.value; }));

                // 0.118 is about 1/4" which is probably acceptable between the top and bottom of a window
                // it's probably not acceptable between the top and middle of a window, but that really complicates things
                // if we go too small with the deviation check then we might as well just show the warning all the time
                return deviation >= 0.12; 
            });
        }
    });

    return data;
}