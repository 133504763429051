// not a generic class to handle fractions, but one specific to this app, so it only supports fractions in 1/8 intervals.
export default class Fraction {
    static values = [
        {text: '⅛', value: 0.125},
        {text: '¼', value: 0.25},
        {text: '⅜', value: 0.375},
        {text: '½', value: 0.5},
        {text: '⅝', value: 0.625},
        {text: '¾', value: 0.75},
        {text: '⅞', value: 0.875},
    ];

    static getByValue(value) {
        for (let i = 0; i < this.values.length; i++) {
            if (this.values[i].value == value) {
                return this.values[i];
            }
        }
        return 0;
    }

    static getByText(text) {
        for (let i = 0; i < this.values.length; i++) {
            if (this.values[i].text == text) {
                return this.values[i];
            }
        }
        return '';
    }

    // splits either a string (converting it to numeric) or numeric value into the whole and decimal components
    // breaks if you feed it an object or a string that can't be converted to numeric
    // if it's a string, it expects the fraction component to match one on the internal list of supported fractions
    static split(value) {
        let whole = 0;
        let decimal = 0;

        if (value != null) {
            if (typeof value == 'string') {
                const match = value.match(/^(\d+)?([⅛¼⅜½⅝¾⅞])?"?$/i);

                if (match) {
                    whole = parseInt(match[1] || 0);
                
                    const fraction = match[2];
                    if (fraction) {
                        decimal = this.getByText(fraction).value;
                    }    
                }
                else {
                    // value is not a string with a fraction, probably a decimal
                    const parsed = parseFloat(value);
                    if (parsed) {
                        const split = this.split(parsed);
                        whole = split.whole;
                        decimal = split.decimal;
                    }
                }
            }
            else {
                const rounded = Math.round(value / 0.125) * 0.125;
                whole = Math.trunc(rounded);
                decimal = rounded - whole;
            }
        }

        return { whole, decimal };
    }

    static splitOffFraction(text) {
        return this.getByValue(this.split(text).decimal);        
    }
    
    static format = {
        to: (inches, options = {}) => {
            const inchSuffix = options.suffix || '"';
            const { whole, decimal } = this.split(inches);
            const fraction = this.getByValue(decimal).text || '';

            let result = `${fraction}${inchSuffix}`;
            if (whole > 0) {
                result = `${whole}${result}`;
            }

            return result;
        },
        from: (str) => {
            const { whole, decimal } = this.split(str);

            return whole + decimal;
        },
    }
}