import { computed } from 'vue';
import { setupProductData } from '../../product-data-setup';

export function useCafeCurtains(doNotPersistState) {
    const data = setupProductData(doNotPersistState, {
        heading: 'Café Curtains',
        subheading: 'Airy & Romantic',
        preMeasureTitle: 'Where will you be installing this drapery?',
        type: 'cafeCurtains',
        defaultCartImage: 'cart-placeholder-cafecurtains.jpg',
        packageStepId: 'package',
        stepsPerGroup: 7,
        steps: {
            package: {
                id: 'package',
                category: 'configure',
                name: 'Configuration',
                layout: 'button-group',
                showOnConfirmation: false,
                options: [
                    { label: 'Café Curtains + Hardware', id: 'drapery-hardware', image: 'icon-drapery-hardware.png' },
                    { label: 'Café Curtains Only', id: 'drapery-only', image: 'icon-drapery-only.png' },
                    // { label: 'Hardware Only', id: 'hardware-only', image: 'icon-hardware-only.png', skipMeasurer: true }
                    { label: 'Hardware Only', id: 'hardware-only', image: 'icon-hardware-only.png' }
                ],
            },
            fabric: {
                id: 'fabric',
                category: 'configure',
                name: 'Fabric & Color',
                layout: 'swatches',
                packages: ['drapery-hardware', 'drapery-only'],
                checkStock: true,
                showOnConfirmation: true,
                options: [
                    { category: 'Sheers', label: 'Sheers • Moon', id: 'MOSHR', image: 'swatch_moonsheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Bone', id: 'BOSHR', image: 'swatch_bonesheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Pebble', id: 'PBSHR', image: 'swatch_pebblesheer.jpg' },
                    // { category: 'Everhem X HTH', label: 'Cream', id: 'HTHCM', image: 'swatch_HTHCM.jpg' },
                    // { category: 'Everhem X HTH', label: 'Sage', id: 'HTHSG', image: 'swatch_HTHSG.jpg' },
                    // { category: 'Everhem X HTH', label: 'Nutmeg', id: 'HTHNT', image: 'swatch_HTHNT.jpg' },
                    { category: 'Hues', label: 'Hues • Quartz', id: 'QZHUE', image: 'swatch_quartz.jpg' },
                    { category: 'Hues', label: 'Hues • Driftwood', id: 'DRHUE', image: 'swatch_driftwood.jpg' },
                    { category: 'Hues', label: 'Hues • Flax', id: 'FLHUE', image: 'swatch_flax.jpg' },
                    { category: 'Hues', label: 'Hues • Granite', id: 'GRHUE', image: 'swatch_granite.jpg' },
                    { category: 'Hues', label: 'Hues • Umber', id: 'UMHUE', image: 'swatch_umber.jpg' },
                    { category: 'Hues', label: 'Hues • Cognac', id: 'CGHUE', image: 'swatch_cognac.jpg' },
                    { category: 'Hues', label: 'Hues • Juniper', id: 'JUHUE', image: 'swatch_juniper.jpg' },
                ],
            },
            pleat: {
                id: 'pleat',
                category: 'configure',
                name: 'Pleat',
                layout: 'button-group',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: true,
                options: [
                    { label: 'Box', id: 'box', image: 'pleat-box-reverse.svg' },
                    { label: 'Tailored', id: 'tailored', image: 'pleat-euro-tailored.svg' },
                    { label: 'Pinch', id: 'pinch', image: 'pleat-french-pinch.svg' }
                ],
            },
            hardwareFinish: {
                id: 'hardwareFinish',
                category: 'configure',
                name: 'Finish',
                layout: 'big-circles',
                packages: ['drapery-hardware', 'hardware-only'],
                showOnConfirmation: true,
                options: [
                    { label: 'Brass', id: 'satin-brass', image: 'finish-aged-brass.jpg' },
                    { label: 'Bronze', id: 'oil-rubbed-bronze', image: 'finish-oil-rubbed-bronze.jpg' },
                    { label: 'Black', id: 'matte-black', image: 'finish-matte-black.jpg', default: true },    
                ],
            },
            mountPosition: {
                id: 'mountPosition',
                category: 'configure',
                name: 'Mount Position',
                layout: 'depth',
                packages: ['drapery-hardware', 'hardware-only'],
                showOnConfirmation: true,
                options: [
                    { label: 'Inside', id: 'inside-mount', image: 'Cafe_Curtain_30x30-02.svg', depthMeasurementRequired: true, alertImage: 'https://everhem.blob.core.windows.net/assets/cafe_alert_inside.svg', alert: 'Rounding down to the nearest 1/8 of an inch, measure the <a class="window-depth-pic-link">flat mounting surface</a> available inside your window. You will need a minimum depth of ¾” for the Cafe Curtains to function properly.' },
                    { label: 'Outside', id: 'outside-mount', image: 'Cafe_Curtain_OutsideMount_30x30.svg', alertImage: 'https://everhem.blob.core.windows.net/assets/cafe_alert_outside.svg', alert: 'Rounding down to the nearest 1/8 of an inch, measure the <a class="window-depth-pic-link">flat mounting surface</a> available on your window’s trim/drywall. You will need a minimum space of ¾” for the Cafe Curtains to install properly.' },
                ],
                rulers: [
                    { id: 'depth', label: 'Depth', min: computed(() => 0.75), max: computed(() => 2), precision: 0.125, starting: 1.0 }
                ]
            },
            rodSize: {
                id: 'rodSize',
                category: 'configure',
                name: 'Size',
                layout: 'length',
                packages: ['drapery-hardware', 'hardware-only'],
                showOnConfirmation: true,
                options: [
                    { label: '3/8 " Rod', id: '0.375', image: 'Cafe_Curtain_30x30-03.svg' },
                ],
                rulers: [
                    // { id: 'length', label: 'Length', min: 12, max: 288, precision: 1, starting: 12.0 }
                    { id: 'windowWidthA', label: 'Length', min: computed(() => 12), max: computed(() => 50), precision: 1, starting: 12.0 }
                ]
            },
            // measure the window
            windowHeight: {
                id: 'windowHeight',
                category: 'measure',
                name: 'Total Height',
                title: 'Measure Total Height',
                secondaryTitle: 'Total Mounting Height',            
                layout: 'rulers',
                packages: ['drapery-hardware', 'drapery-only'],
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'inside-mount-width',
                        key: 'inside-mount',
                        html: '<p>Everhem Experts recommend mounting at the vertical halfway point on your window. If there are grid lines on your windows, we recommend mounting in line with the horizontal grid line that lands closest to the vertical halfway point.</p><p>Starting from the approximate mounting height found, measure the length from that point down to the bottom inside edge of the window, rounding down to the nearest 1/8” of an inch. Record the measurement below.</p>',
                        image: 'diagram-cafe-curtains-height.svg',
                        instructions: { title: 'How to Measure', file: '' },
                        measureGuide: 'Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                ],
                rulers: [
                    { id: 'windowHeightA', label: 'A', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                    { id: 'windowHeightB', label: 'B', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                    { id: 'windowHeightC', label: 'C', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                ],
            },
            windowWidth: {
                id: 'windowWidth',
                category: 'measure',
                name: 'Measure Width',
                title: 'Measure Window Width',
                secondaryTitle: 'Total Width',                
                layout: 'rulers',
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'inside-mount-width',
                        key: 'inside-mount',
                        html: '<p>Along the approximate mounting height found, measure the width from inside edge to inside edge of the window, rounding down to the nearest 1/8” of an inch. Record the measurements below.</p>',
                        image: 'diagram-cafe-curtains-inside-width.svg',
                        instructions: { title: 'How to Measure for "Inside Mount Shades"', file: '' },
                        measureGuide: 'Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                ],
                rulers: [
                    { id: 'windowWidthA', label: 'A', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                    { id: 'windowWidthB', label: 'B', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                    { id: 'windowWidthC', label: 'C', min: computed(() => 8), max: computed(() => 50), precision: 1, starting: 35.0 },
                ],
            },
            panels: {
                id: 'panels',
                category: 'measure',
                name: 'Panels',
                title: 'Choose Panel Configuration',
                layout: 'big-circles',
                packages: ['drapery-hardware', 'drapery-only'],
                description: '<p>We generally recommend Double Panel for Café Curtains.</p>',
                showOnConfirmation: true,
                recommendedOption: computed(() => {                    
                    return data.steps.panels.options.find(option => option.id == 'double');
                }),
                options: [
                    { label: 'Single Panel', id: 'single', image: 'cafe-curtain-panels-single.svg', numPanels: 1, minWidth: 0, maxWidth: 50, recommendAt: 51 }, // never recommend single panel
                    { label: 'Double Panel', id: 'double', image: 'cafe-curtain-panels-double.svg', numPanels: 2, minWidth: 0, maxWidth: 50, recommendAt: 0, default: true },
                ],
            }  
        },
    });

    // add measurement total computations
    data.getTotals = function() {
        let totals = [];

        if (data.currentPackage.value.skipMeasurer) {
            totals = [
                {
                    layout: 'individual',
                    values: [
                        { label: 'Rod Length', id: 'rod-length', getValue: () => {
                            return Number(data.steps.rodSize.rulersMax.value);
                        }},
                    ]
                }
            ]
        } else {
            totals = [   
                {
                    layout: 'individual',
                    values: [
                        { label: 'Height', id: 'height', getValue: () => {
                            return Number(data.steps.windowHeight.rulersMax.value);
                        }},
                        { label: 'Width', id: 'width', deduction: true, getValue: () => {
                            return Number(data.steps.windowWidth.rulersMax.value);
                        }},
                    ]
                },
                {
                    layout: 'text',
                    values: [
                        { label: 'Panel Dimensions', id: 'total', getValue: () => {
                            const totalWidth = Number(totals[0].values[1].getValue());
                            const totalHeight = Number(totals[0].values[0].getValue());

                            const numPanels = Number(data.steps.panels.selected.value.numPanels);

                            return `${totalWidth/numPanels}"W x ${totalHeight - 1}"L (X${numPanels})`;
                        }},
                    ]
                }
            ];
        }
        // const totals = [   
        //     {
        //         layout: 'individual',
        //         values: [
        //             { label: 'Height', id: 'height', getValue: () => {
        //                 return Number(data.steps.windowHeight.rulersMax.value);
        //             }},
        //             { label: 'Width', id: 'width', getValue: () => {
        //                 return Number(data.steps.windowWidth.rulersMax.value);
        //             }},
        //         ]
        //     },
        //     {
        //         layout: 'text',
        //         values: [
        //             { label: 'Panel Dimensions', id: 'total', getValue: () => {
        //                 const totalWidth = Number(totals[0].values[1].getValue());
        //                 const totalHeight = Number(totals[0].values[0].getValue());

        //                 const numPanels = Number(data.steps.panels.selected.value.numPanels);

        //                 return `${totalWidth/numPanels}"W x ${totalHeight}"L (X${numPanels})`;
        //             }},
        //         ]
        //     }        
        // ];

        return totals;
    }

    // add rendering layers
    data.rendering = {
        fabric: {
            id: 'fabric',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const mountSelected = data.steps.mountPosition.selected.value.id;
                const fabricSelected = data.steps.fabric.selected.value.id;
                const pleatSelectedValue = data.steps.pleat.selected.value.id;
                
                return `/cafe-curtains/${mountSelected}-${fabricSelected}-${pleatSelectedValue}.png`;

                // inside-mount-SKU-tailored
            }
        },
        hardware: {
            id: 'hardware',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const mountSelected = data.steps.mountPosition.selected.value.id;
                const hardwareSelected = data.steps.hardwareFinish.selected.value.id;
                const pleatSelectedValue = data.steps.pleat.selected.value.id;
                
                return `/cafe-curtains/hardware/${mountSelected}-${hardwareSelected}-${pleatSelectedValue}.png`;
            }
        }
    }    

    return data;
}