import { computed } from 'vue';
import { setupProductData } from '../../product-data-setup';
import { Helpers } from '@/helpers';

export function useRomanShades(doNotPersistState) {
    const data = setupProductData(doNotPersistState, {
        heading: 'Roman Shades',
        subheading: 'Soft & Welcoming',
        preMeasureTitle: 'Where will you be installing these roman shades?',
        type: 'roman-shades',
        defaultCartImage: 'cart-placeholder-romanshades.jpg',
        stepsPerGroup: 3,
        steps: {
            // configure options
            fabric: {
                id: 'fabric',
                category: 'configure',
                name: 'Fabric & Color',
                layout: 'swatches',
                checkStock: true,
                showOnConfirmation: true,
                options: [
                    { category: 'Essentials', label: 'Essentials • Moon', id: 'MOESS', image: 'swatch_moon.jpg' },
                    { category: 'Essentials', label: 'Essentials • Bone', id: 'BOESS', image: 'swatch_bone.jpg' },
                    { category: 'Essentials', label: 'Essentials • Sand', id: 'SDESS', image: 'swatch_sand.jpg' },
                    { category: 'Essentials', label: 'Essentials • Birch', id: 'BCESS', image: 'swatch_birch.jpg' },
                    { category: 'Essentials', label: 'Essentials • Ash', id: 'ASESS', image: 'swatch_ash.jpg' },
                    { category: 'Essentials', label: 'Essentials • Sage', id: 'SGESS', image: 'swatch_sage.jpg' },
                    { category: 'Essentials', label: 'Essentials • Rose', id: 'RSESS', image: 'swatch_rose.jpg' },
                    { category: 'Stripes', label: 'Stripes • Ivory Stripe', id: 'IVSTR', image: 'swatch_ivory-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Oyster Stripe', id: 'OYSTR', image: 'swatch_oyster-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Denim Stripe', id: 'DESTR', image: 'swatch_denim-stripe.jpg' },
                    { category: 'Stripes', label: 'Stripes • Petal Stripe', id: 'PESTR', image: 'swatch_petal-stripe.jpg' },
                    // { category: 'Stripes & Trim', label: 'Stripes & Trim • Ivory Trim', id: 'MOIVT', image: 'swatch_ivory.jpg' },
                    // { category: 'Stripes & Trim', label: 'Stripes & Trim • Oyster Trim', id: 'MOOYT', image: 'swatch_oyster.jpg' },
                    // { category: 'Stripes & Trim', label: 'Stripes & Trim • Denim Trim', id: 'MODET', image: 'swatch_denim.jpg' },
                    // { category: 'Stripes & Trim', label: 'Stripes & Trim • Petal Trim', id: 'MOPET', image: 'swatch_petal.jpg' },
                    { category: 'Sheers', label: 'Sheers • Moon', id: 'MOSHR', image: 'swatch_moonsheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Bone', id: 'BOSHR', image: 'swatch_bonesheer.jpg' },
                    { category: 'Sheers', label: 'Sheers • Pebble', id: 'PBSHR', image: 'swatch_pebblesheer.jpg' },
                    // { category: 'Everhem X HTH', label: 'Cream', id: 'HTHCM', image: 'swatch_HTHCM.jpg' },
                    // { category: 'Everhem X HTH', label: 'Sage', id: 'HTHSG', image: 'swatch_HTHSG.jpg' },
                    // { category: 'Everhem X HTH', label: 'Nutmeg', id: 'HTHNT', image: 'swatch_HTHNT.jpg' },
                    { category: 'Hues', label: 'Hues • Quartz', id: 'QZHUE', image: 'swatch_quartz.jpg' },
                    { category: 'Hues', label: 'Hues • Driftwood', id: 'DRHUE', image: 'swatch_driftwood.jpg', alert: 'Driftwood lead time is currently 14 weeks.' },
                    { category: 'Hues', label: 'Hues • Flax', id: 'FLHUE', image: 'swatch_flax.jpg' },
                    { category: 'Hues', label: 'Hues • Granite', id: 'GRHUE', image: 'swatch_granite.jpg' },
                    { category: 'Hues', label: 'Hues • Umber', id: 'UMHUE', image: 'swatch_umber.jpg' },
                    { category: 'Hues', label: 'Hues • Cognac', id: 'CGHUE', image: 'swatch_cognac.jpg' },
                    { category: 'Hues', label: 'Hues • Juniper', id: 'JUHUE', image: 'swatch_juniper.jpg' },
                    // { category: 'Sale', label: 'Essentials • Pebble', id: 'PBESS', image: 'swatch_pebble.jpg' },
                    { category: 'Sale', label: 'Essentials • Fawn', id: 'FWESS', image: 'swatch_fawn.jpg' },
                    { category: 'Sale', label: 'Essentials • Storm', id: 'SMESS', image: 'swatch_storm.jpg' },
                ],
            },
            style: {
                id: 'style',
                category: 'configure',
                name: 'Style',
                layout: 'button-group',
                showOnConfirmation: true,
                options: [
                    { label: 'Pleated', id: 'pleated', image: 'style-rs-pleated.png' },
                    { label: 'Flat', id: 'flat', image: 'style-rs-flat.png' },
                ],
            },            
            lining: {
                id: 'lining',
                category: 'configure',
                name: 'Lining',
                layout: 'button-group',
                description: 'Determine how much light your shade allows to pass through.',
                // video: { title: 'Learn More About Linings', yt_id: 'u053-4CdzR0' },
                showOnConfirmation: true,
                conflicts: {
                    stepId: 'fabric', // id of the step that has options that conflict with the options in THIS step
                    optionProperty: 'category'
                },
                options: [
                    { label: 'Unlined', id: 'unlined', image: 'icon-lining-unlined.svg' },
                    { label: 'Privacy', id: 'privacy', image: 'icon-lining-privacy.svg', incompatibleOptions: ['Sheers'], default: true },
                    { label: 'Blackout', id: 'blackout', image: 'icon-lining-blackout.svg', incompatibleOptions: ['Sheers'] },
                ],
            },
            mountPosition: {
                id: 'mountPosition',
                category: 'configure',
                name: 'Mount Position',
                layout: 'depth',
                cascadeSelectionToStepIds: [ 'windowWidth', 'windowHeight' ],
                showOnConfirmation: true,
                options: [
                    { label: 'Inside', id: 'inside-mount', image: 'icon-mount-inside.png', depthMeasurementRequired: true, alertImage: 'https://everhem.blob.core.windows.net/assets/window-depth.jpg', alert: 'Rounding down to the nearest 1/4 of an inch, measure the <a class="window-depth-pic-link">flat mounting surface</a> available on your window.' },
                    { label: 'Outside', id: 'outside-mount', image: 'icon-mount-outside.png' },
                ],
                rulers: [
                    { id: 'mountdepth', label: 'Depth', min: computed(() => 2), max: computed(() => 3.50), precision: 0.25, starting: 2.0 }
                ]
            },
            customControl: {
                id: 'customControl',
                category: 'configure',
                name: 'Custom Control',
                layout: 'button-group',
                getDepthFromStepId: 'mountPosition',
                showOnConfirmation: true,
                options: [
                    { label: 'Chain Loop', id: 'chain-loop', image: 'icon_chain-loop.svg', minDepth: 2, alert: 'Chain loop requires at least 2" of mounting depth.', alertOutside: 'Chain loop will project at least 2" from the wall.' },
                    { label: 'Cordless', id: 'cordless', image: 'icon_cordless.svg', minDepth: 2, alert: 'Cordless requires at least 2" of mounting depth.', alertOutside: 'Cordless will project at least 2" from the wall.' },
                    { label: 'Motorization', id: 'motorization', image: 'icon_motorization.svg', minDepth: 2, alert: 'Motorization requires at least 2" of mounting depth.', alertOutside: 'Motorization will project at least 2" from the wall.' },
                    // { label: 'Cord Lock', id: 'cord-lock', image: 'icon_cord-lock.svg', minDepth: 0 },
                    // { label: 'Top Down Bottom Up', id: 'top-down-bottom-up', image: 'icon_tdbu.svg', minDepth: 0 },
                ],
            },
            shadeOperation: {
                id: 'shadeOperation',
                category: 'configure',
                name: 'Shade Operation',
                layout: 'big-button-group',
                showOnConfirmation: true,                
                options: [
                    { label: 'Front', id: 'front', image: 'icon_cord-placement_front.png', description: 'Chain is visible when shade is drawn closed for easy access when opening and closing. Valance is added for this option.<br/><br/>Recommended for Outside Mount Blackout Shades.' },    
                    { label: 'Back', id: 'back', image: 'icon_cord-placement_back.png', default: true, description: 'Chain will not be visible when shade is drawn closed, for a clean look.' },
                ],
            },    
            // measure the window
            windowWidth: {
                id: 'windowWidth',
                category: 'measure',                
                name: 'Window Width',
                title: 'Measure Window Width',
                secondaryTitle: 'Shade Width',
                layout: 'rulers',
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'inside-mount', // needs to match the id of the parent property (mountPosition)
                        html: '<p>Rounding down to the nearest ⅛ of an inch, measure the inside edges of the window frame in 3 places and record the measurement.</p>',
                        image: 'diagram-measure-inside-width.svg',
                        // @todo Update Instructions
                        // instructions: { title: 'How to Measure for "Inside Mount Shades"', yt_id: 'YhrPDZlwQDM' },
                        // measureGuide: 'Follow Our <a target="_blank" href="https://www.everhem.com/guides?guide-type=Measure">Guide Here</a>.',
                        rulerAdjustment: {
                            label: 'Deduction',
                            tip: {
                                title: 'Our designers recommend...',
                                html: 'For Inside Mount shades, we take a ¼” deduction from the window width entered to ensure the perfect fit.',
                            },
                            starting: () => {
                                return -0.25;
                            }
                        }
                    },
                    {
                        id: 'outside-mount', // needs to match the id of the parent property (mountPosition)
                        html: '<p>Rounding up to the nearest ⅛ of an inch, measure the outside edges of the window frame width in 3 places. To ensure your window will be fully covered, we recommend adding about 2 inches total to the width you measured. After accounting for your desired additional width, record the total measurement below.</p>',
                        image: 'diagram-measure-outside-width.svg',
                        // instructions: { title: 'How to Measure for "Outside Mounting"', yt_id: 'LFBurJbiFkk' },                        
                    }
                ],
                rulers: [
                    { id: 'windowWidthA', label: 'A', min: computed(() => calcRulerMinOrMax('width', 'min')), max: computed(() => calcRulerMinOrMax('width', 'max')), precision: 1, starting: 36.0 },
                    { id: 'windowWidthB', label: 'B', min: computed(() => calcRulerMinOrMax('width', 'min')), max: computed(() => calcRulerMinOrMax('width', 'max')), precision: 1, starting: 36.0 },
                    { id: 'windowWidthC', label: 'C', min: computed(() => calcRulerMinOrMax('width', 'min')), max: computed(() => calcRulerMinOrMax('width', 'max')), precision: 1, starting: 36.0 },
                ],
            },
            windowHeight: {
                id: 'windowHeight',
                category: 'measure',
                name: 'Measure Height',
                title: 'Measure Window Height',
                secondaryTitle: 'Shade Height',
                layout: 'rulers',
                showOnConfirmation: false,
                doNotSendSelectedToBackend: true,
                options: [
                    { 
                        id: 'inside-mount', // needs to match the id of the parent property (mountPosition)
                        html: '<p>Rounding up to the nearest ⅛ of an inch, measure the <strong>inside</strong> edges of the window frame in 3 places and record the measurements below.</p>',
                        image: 'diagram-measure-inside-height.svg',
                        // @todo Update Instructions
                        // instructions: { title: 'How to Measure for "Inside Mounting"', yt_id: 'YhrPDZlwQDM' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.'
                    },
                    {
                        id: 'outside-mount', // needs to match the id of the parent property (mountPosition)
                        html: '<p>Rounding up to the nearest 1/8 of an inch, measure the <strong>outside edge</strong> of the window frame or trim in 3 places and record the measurements below.<br/><br/>Next, enter your additional desired Mounting Height. We recommending adding at least 5 inches for the Mounting Height to ensure that the window will be fully covered once shade is mounted.<br/><br/><em>Please Note: If you\'ve selected Shade Operation Front and are mounting on drywall above the window, your window trim\'s projection should be less than 3/4”. If the projection is greater than 3/4”, please contact us for a custom solution.</em></p>',
                        image: 'diagram-measure-outside-height.svg',
                        // instructions: { title: 'How to Measure for "Outside Mounting"', yt_id: 'LFBurJbiFkk' },
                        // measureGuide: 'Or Follow Our <a target="_blank" href="https://everhem.blob.core.windows.net/assets/measurement-guide.pdf">Guide Here</a>.',
                        rulerAdjustment : { 
                            label: 'Mounting Height',
                            tip: {
                                title: 'Designer Recommended Mounting Height:',
                                html: '<p><strong>Wall above window*:</strong>5" recommended</br><strong>Door:</strong>2" above window</br><strong>Window Trim:</strong>Select 0" but ensure you have 2" of flat moounting surface on trim.<br/><br/><em>Please Note: If you\'ve selected Shade Operation Front and are mounting on drywall above the window, your window trim\'s projection should be less than 3/4”. If the projection is greater than 3/4”, please contact us for a custom solution.</em></p>',
                            },
                            range: {
                                enabled: computed(() => { return true; }),
                                min: 0,
                                max: 7,
                                precision: 0.5,
                            },
                            starting: () => {
                                return 5;
                            }
                        },
                    }
                ],
                rulers: [
                    { id: 'windowHeightA', label: 'A', min: computed(() => calcRulerMinOrMax('height', 'min')), max: computed(() => calcRulerMinOrMax('height', 'max')), precision: 1, starting: 36.0 },
                    { id: 'windowHeightB', label: 'B', min: computed(() => calcRulerMinOrMax('height', 'min')), max: computed(() => calcRulerMinOrMax('height', 'max')), precision: 1, starting: 36.0 },
                    { id: 'windowHeightC', label: 'C', min: computed(() => calcRulerMinOrMax('height', 'min')), max: computed(() => calcRulerMinOrMax('height', 'max')), precision: 1, starting: 36.0 },
                ],
            },
            cordPosition: {
                id: 'cordPosition',
                category: 'measure',
                name: 'Control Position',
                title: 'Choose Control Position',
                layout: 'big-circles',
                description: '<p>Which side of your shade would you like your Chain?</p>',
                showOnConfirmation: true,
                conflicts: {
                    stepId: 'customControl', // id of the step that has options that conflict with the options in THIS step
                    optionProperty: 'id'
                },
                options: [
                    { label: 'Left', id: 'left', image: 'RomanCordLeft.jpg', incompatibleOptions: ['cordless'] },
                    { label: 'Right', id: 'right', image: 'RomanCordRight.jpg', incompatibleOptions: ['cordless'] },
                    { label: 'Cordless', id: 'center', image: 'RomanCordRight.jpg', incompatibleOptions: ['chain-loop', 'motorization'] },
                ],
            }
        },
    });    

    // add measurement total computations
    data.getTotals = function() { 
        const totals = {
            id: 'totals',
            category: 'confirm',
            layout: 'individual',
            values: [
                { label: 'Shade Width', id: 'width', getValue: () => {

                    let addedTotalWidth = 0;
                    const rulerAdjustmentValue = data.steps?.windowWidth?.selected?.value?.rulerAdjustment;

                    if (rulerAdjustmentValue !== undefined) {
                        addedTotalWidth = data.steps.windowWidth.rulersMax.value + data.steps.windowWidth.selected.value.rulerAdjustment.current.value;
                    } else {
                        addedTotalWidth = data.steps.windowWidth.rulersMax.value;
                    }

                    return Number(addedTotalWidth);
                }},
                { label: 'Shade Height', id: 'height', getValue: () => {
                    // let addedWindowHeight = 0;
                    // const rulerHieghtAdjustmentValue = data.steps?.windowHeight?.selected?.value?.rulerAdjustment;

                    // if (rulerHieghtAdjustmentValue !== undefined) {
                    //     addedWindowHeight = data.steps.windowHeight.rulersMax.value + data.steps.windowHeight.selected.value.rulerAdjustment.current.value;
                    // } else {
                    //     addedWindowHeight = data.steps.windowHeight.rulersMax.value;
                    // }

                    return Number(data.steps.windowHeight.rulersMax.value);
                }},                
            ]            
        };

        // only add mount position measurement if selected mount position requires a depth (ie, inside mount)
        if (data.steps.mountPosition.selected.value.depthMeasurementRequired) {
            totals.values.push({
                label: 'Mount Depth', id: 'mount-depth', getValue: () => {
                    return Number(data.steps.mountPosition.rulersMax.value);
                }      
            });
        }

        // only add mount height measurement if selected mount position requires a mounting height (ie, outside mount)
        if (data.steps.windowHeight.selected.value.mountingHeight) {
            
            totals.values.push({
                label: 'Mount Height', id: 'mount-height', getValue: () => {
                    return Number(data.steps.windowHeight.selected.value.mountingHeight.current.value);
                }
            });
        }

        return [totals];
    }

    // add rendering layers
    data.rendering = {
        valance: {
            id: 'valance',
            shouldShow: () => { 
                return data.steps.shadeOperation.selected.value.id === 'front'; 
            },
            getImageUrl: () => {
                const mountSelected = data.steps.mountPosition.selected.value.id;
                const fabricSelected = data.steps.fabric.selected.value.id;
                const liningSelected = data.steps.lining.selected.value.id;
            
                return `/roman-shades/valance/${mountSelected}_${fabricSelected}_${liningSelected}.png`;
            }
        },
        fabric: {
            id: 'fabric',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const mountSelected = data.steps.mountPosition.selected.value.id;
                const fabricSelected = data.steps.fabric.selected.value.id;
                const liningSelected = data.steps.lining.selected.value.id;
                const pleatSelectedValue = data.steps.style.selected.value.id;

                return `/roman-shades/fabric/${mountSelected}_${fabricSelected}_${liningSelected}_${pleatSelectedValue}.png`;    
            }
        },
        hardware: {
            id: 'hardware',
            shouldShow: () => { return true; },
            getImageUrl: () => {
                const mountSelected = data.steps.mountPosition.selected.value.id;
                const customControl = data.steps.customControl.selected.value.id;
                const shadeOperation = data.steps.shadeOperation.selected.value.id;
                const controlPosition = data.steps.cordPosition.selected.value.id;

                let addedCustomControl = ''
                if (customControl === 'motorization') {
                    addedCustomControl = 'cordless';
                } else {
                    addedCustomControl = customControl
                }
        
                let addedControlPosition = '';
                if (controlPosition === 'right') {
                    addedControlPosition = '_' + controlPosition;
                } else {
                    addedControlPosition =  '';
                }
        
                return `/roman-shades/custom-controls/${mountSelected}_${addedCustomControl}_${shadeOperation}${addedControlPosition}.png`;        
            }
        },
        tdbu: {
            id: 'tdbu',
            shouldShow: () => {
                return data.steps.customControl.selected.value.id === 'top-down-bottom-up';
            },
            getImageUrl: () => {
                return '';
            }
        }
    }

    // ruler min/max calculator
    function calcRulerMinOrMax(widthOrHeight, minOrMax) {
        const lookup = {
            'default': {
                'default': {
                    'width': {
                        0: { min: 10, max: 72 }
                    },
                    'height': {
                        0: { min: 12, max: 120 }
                    }                  
                }
            },
            'inside-mount': {
                'chain-loop': {
                    'width': {
                        2: { min: 10, max: 72 },
                        2.75: { min: 10, max: 104 },
                        3.50: { min: 10, max: 140 },
                    },
                    'height': {
                        2: { min: 10, max: 72 },
                        2.75: { min: 10, max: 90 },
                        3.50: { min: 10, max: 70 },
                    }                   
                },
                'cordless': {
                    'width': {
                        2: { min: 30, max: 72 },
                        2.75: { min: 30, max: 78 },
                        3.50: { min: 30, max: 78 },
                    },
                    'height': {
                        2: { min: 10, max: 90 },
                        2.75: { min: 10, max: 84 },
                        3.50: { min: 10, max: 84 },
                    }
                },
                'motorization': {
                    'width': {
                        2: { min: 24, max: 72 },
                        2.75: { min: 24, max: 104 },
                        3.50: { min: 10, max: 140 },
                    },
                    'height': {
                        2: { min: 10, max: 72 },     
                        2.75: { min: 10, max: 84 },
                        3.50: { min: 10, max: 90 },
                    }
                },
            },
            'outside-mount': {
                'chain-loop': {
                    'width': {
                        0: { min: 10, max: 140 },
                    }                    
                },
                'cordless': {
                    'width': {
                        0: { min: 30, max: 78 },
                    }                    
                },
                'motorization': {
                    'width': {
                        0: { min: 24, max: 144 },
                    }                    
                },
            }
        };

        return Helpers.calcRulerMinOrMax(data, lookup, widthOrHeight, minOrMax);
    }

    return data;
}