import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'throttle-debounce';
import { Helpers } from '@/helpers';

export function useUrlStorage() {
  const router = useRouter();
  const route = useRoute();

  let doStoreHydrate = null;
  let pushHistory = true;

  function convertToProperType(value) {
    if (value.toLowerCase() === 'true') {
      value = true;
    }
    else if (value.toLowerCase() === 'false') {
      value = false;
    }
    else if (!isNaN(value) && !isNaN(parseFloat(value))) {
      value = parseFloat(value);
    }

    return value;
  }

  const debounceSetItem = debounce(800, (jsonString) => {
    // flatten json object
    const query = {};
    const jsonObject = JSON.parse(jsonString);

    Helpers.jsonToQuery(query, jsonObject);

    router.push({ name: route.name, params: { product: route.params.product, }, query: query });
  });

  // required for Pinia persistence plugin
  const getItem = function() {
    // unflatten query string
    const jsonObject = {};

    Object.keys(route.query).forEach(function(propName) {
      const levels = propName.split('.');
      if (levels.length == 1) {
        // property doesn't have any children
        jsonObject[propName] = convertToProperType(route.query[propName]);
      }
      else {
        // property has children, so go digging
        let currentObject = jsonObject;
        for (let i = 0; i < levels.length; i++) {
          const currentLevel = levels[i];

          if (i+1 == levels.length) {
            // this is the last level, so set the value
            let value = convertToProperType(route.query[propName]);
            currentObject[currentLevel] = value;
          }
          else {
            // there are more levels
            
            if (jsonObject[currentLevel] == null) {
              // make sure that this level has been initialized
              jsonObject[currentLevel] = {};
            }

            currentObject = jsonObject[currentLevel];
          }
        }  
      }
    });    

    return JSON.stringify(jsonObject); // pinia sends and receives json strings
  };

  // required for Pinia persistence plugin
  const setItem = function(key, val) {
    if (pushHistory) { 
      debounceSetItem(val);
    }
  }

  // function called by our StateStore implementation
  const config = function(hydrateFunction) {
    doStoreHydrate = hydrateFunction;
  }

  // listen for history changes so state can be refreshed from url
  addEventListener("popstate", () => {
    pushHistory = false;
    doStoreHydrate();
    pushHistory = true;
  });

  return {
    config,
    getItem,
    setItem
  }
}