import { useRoute } from 'vue-router';
import { useCafeCurtains } from '@/composables/product-data/CafeCurtains';
import { useDrapery } from '@/composables/product-data/Drapery';
import { useRomanShades } from '@/composables/product-data/RomanShades';
import { useWovenWoods } from '@/composables/product-data/WovenWoods';

export function useData(product, doNotPersistState) {
  // if product isn't already specified, get it from the route
  if (!product) {
    const route = useRoute();
    product = route.params.product;
  }

  // use url parameter to figure out the current product    
  switch(product) {
    case 'cafe-curtains':
      return useCafeCurtains(doNotPersistState);
    case 'drapery':
      return useDrapery(doNotPersistState);
    case 'roman-shades':
      return useRomanShades(doNotPersistState);
    case 'woven-woods':
        return useWovenWoods(doNotPersistState);
    default:
      return false; // this will execute when the page is some other webflow page
  } 
}