import { Helpers } from '@/helpers';

export function useMemoryStorage(query) {
  let jsonObject = {};

  // required for Pinia persistence plugin
  const getItem = function() {    
    return JSON.stringify(jsonObject); // pinia sends and receives json strings
  };

  // required for Pinia persistence plugin
  const setItem = function(key, val) {
    jsonObject = JSON.parse(val);

    // flatten json and set query variable so it can be accessed externally
    Helpers.jsonToQuery(query, jsonObject);
  }

  // function called by our StateStore implementation
  const config = function(hydrateFunction) {
    hydrateFunction; // do nothing
  }

  return {
    config,
    getItem,
    setItem
  }
}